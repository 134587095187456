import "../../Styles/requests.scss";
import AccordionBody from "../Utils/AccordionBody";
import { useEffect, useState } from "react";
import Pagination from "../Utils/Pagination";
import WaveLoading from "../Utils/WaveLoading";
import MyError from "../Utils/MyError";

export default function Requests(props) {
  const [requestsn, setRequestsn] = useState(null);
  const [active, setActive] = useState("New Jobs");
  const [requestsc, setRequestsc] = useState(null);
  const [show, setShow] = useState(false);
  const [offsetn, setOffsetn] = useState(0);
  const [offseta, setOffseta] = useState(0);
  const [offsetc, setOffsetc] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showing, setShowing] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [UserID, setUserID] = useState(null);
  const [arrow, setArrow] = useState("fa fa-angle-up");
  const jwt = require("jsonwebtoken");
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("webgfgfgggn");
    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/";
        } else {
          setUserID(decoded);
        }
      } catch (error) {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
  }, []);

  const toggle = () => {
    setShow(!show);
    if (show) {
      setArrow("fa fa-angle-down");
    } else setArrow("fa fa-angle-up");
  };

  useEffect(() => {
    if (UserID) {
      setLoading(true);
      fetch(`/api/uber/client/paginated/Received/${offsetn}/${UserID.UserID}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          console.log(data);
          setLoading(false);
          setRequestsn(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [offsetn, refresh, UserID]);

  useEffect(() => {
    if (UserID) {
      fetch(`/api/uber/client/paginated/Complete/${offsetc}/${UserID.UserID}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          console.log(data);
          setRequestsc(data);
        })
        .catch((err) => {});
    }
  }, [offsetc, refresh, UserID]);

  return (
    <div className="requests">
      <button
        onClick={() => {
          window.location.href = "/home/checks";
        }}
        className="bt"
      >
        New Check
      </button>
      <div className="selector">
        <div className="cont">
          <Selector txt="New Jobs" active={active} setActive={setActive} />

          <Selector txt="Completed" active={active} setActive={setActive} />
        </div>
      </div>

      {active == "New Jobs" && (
        <div className="litem">
          <div className="content">
            {requestsn && requestsn?.data?.length > 0 ? (
              requestsn?.data?.map((item, index) => {
                return (
                  <AccordionBody
                    item={item}
                    key={index}
                    type="Received"
                    refresh={refresh}
                    setRefresh={setRefresh}
                    date={item.createdAt.split("T")[0]}
                    file={item.NationalID}
                  />
                );
              })
            ) : (
              <MyError txt="No Data" />
            )}
          </div>

          {requestsn && requestsn?.data?.length > 0 && (
            <>
              <br></br>
              <Pagination
                currentPage={offsetn}
                onPageChange={(v) => {
                  setOffsetn(v);
                }}
                totalItems={requestsn.total}
              />
            </>
          )}
        </div>
      )}
      {active == "Completed" && (
        <div className="litem">
          <div className="content">
            {requestsc && requestsc?.data?.length > 0 ? (
              requestsc?.data?.map((item, index) => {
                return (
                  <AccordionBody
                    item={item}
                    key={index}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    type="Completed"
                    date={item.createdAt.split("T")[0]}
                    file={item.NationalID}
                  />
                );
              })
            ) : (
              <MyError txt="No Data" />
            )}
          </div>

          {requestsc && requestsc?.data?.length > 0 && (
            <>
              <br></br>
              <Pagination
                currentPage={offsetc}
                onPageChange={(v) => {
                  setOffsetc(v);
                }}
                totalItems={requestsc.total}
              />
            </>
          )}
        </div>
      )}

      {loading && <WaveLoading />}
    </div>
  );
}

const Selector = (props) => {
  return (
    <div className={props.active === props.txt ? "active" : "item"}>
      <div></div>
      <h3
        onClick={() => {
          props.setActive(props.txt);
        }}
      >
        {props.txt}
      </h3>
    </div>
  );
};
