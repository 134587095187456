import { useEffect, useRef, useState } from "react";
import { AiFillWarning } from "react-icons/ai";
import {
  Button,
  TextField,
  Typography,
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  Card,
  Stack,
  Grid2,
} from "@mui/material";
import WaveLoading from "../Utils/WaveLoading";
import { Warning } from "@mui/icons-material";

export default function NewDocument(props) {
  const [refresh, setRefresh] = useState(false);
  const [documentId, setDocumentID] = useState(null);
  const [showing, setShowing] = useState(true);
  const [UserID, setUserID] = useState(null);
  const jwt = require("jsonwebtoken");

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("webgfgfgggn");
    if (token) {
      try {
        var decoded = jwt.decode(token);
        console.log(decoded);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/";
        } else {
          setUserID(decoded.UserID);
        }
      } catch (error) {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("request");
    const step2 = localStorage.getItem("step2");
    if (token) {
      setDocumentID(token);
    }
  }, [refresh]);

  return (
    <Box className="requests" sx={{ p: 2 }}>
      <Card sx={{ boxShadow: "0px 4px 16px #60606040", p: "1em" }}>
        <Typography variant="h5">Driver Consent</Typography>
        <Divider />
        <Box className="consent" sx={{ mt: 2 }}>
          <Box display="flex" gap={1} my={1}>
            <Warning color="warning" />
            <Typography variant="body1">
              I give my consent to Arope Group Limited to conduct the Background
              Screening exercise.
            </Typography>
          </Box>
          <Box display="flex" gap={1} my={1}>
            <Warning color="warning" />
            <Typography variant="body1">
              I understand that my participation in the Background Screening
              exercise is voluntary.
            </Typography>
          </Box>
          <Box display="flex" gap={1} my={1}>
            <Warning color="warning" />
            <Typography variant="body1">
              I understand that relevant sections of my bio data and Background
              Checks conducted by Arope Group Limited may be looked at by my
              employer from regulatory authorities or from the government
              security officials, where it is relevant to my taking part in this
              Vetting and Screening. I give my permission for these individuals
              to have access to my records.
            </Typography>
          </Box>
        </Box>
      </Card>
      <Box className="new" sx={{ mt: 2 }}>
        <Step1 refresh={refresh} setRefresh={setRefresh} UserID={UserID} />
      </Box>
    </Box>
  );
}

const Step1 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [amt, setAmt] = useState(0);
  const [body, setBody] = useState({
    UserID: props.UserID,
    Passport: null,
    Police: null,
    NationalID: null,
    MPESA: null,
    Category: "",
    DOB: `${new Date().getFullYear() - 21}-01-01`,
    Phone: "",
    IDNumber: "", // Added ID Number field
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [mpesaCode, setMpesaCode] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [minDate, setMinDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    setBody((prevBody) => ({ ...prevBody, UserID: props.UserID }));
  }, [props.UserID]);

  useEffect(() => {
    if (selectedCategory) {
      const md = new Date();
      if (selectedCategory.includes("Rider")) {
        md.setFullYear(md.getFullYear() - 21);
      } else {
        md.setFullYear(md.getFullYear() - 24);
      }
      const minDateString = md.toISOString().split("T")[0];
      setMinDate(minDateString);
    }
  }, [selectedCategory]);

  const createDocument = (e) => {
    e.preventDefault();
    setError("");

    if (!selectedCategory) {
      setError("Please select a category!");
      return;
    }

    const chck = Object.values(body);
    let valid = true;

    console.log(body);
    chck.forEach((item) => {
      if (item === "" || item == null) {
        valid = false;
      }
    });

    if (body.Phone.length != 10) return setError("Enter a valid phone number!");
    if (body.IDNumber.length < 6 || body.IDNumber.length > 8)
      return setError("Enter a valid ID number!");
    if (!valid) return setError("All fields are required!");
    if (body.NationalID === undefined)
      return setError("NationalID is required!");
    if (body.Police === undefined)
      return setError("Police Certificate is required!");
    if (body.Passport === undefined)
      return setError("Passport Photo is required!");

    setLoading(true);

    const formData = new FormData();
    for (const key in body) {
      formData.append(key, body[key]);
    }

    fetch("/api/uber/create", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/home";
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <Stack spacing={3}>
      <Box
        className="payment"
        sx={{ boxShadow: "0px 4px 16px #60606040", p: "1em" }}
        component={Card}
      >
        <Typography variant="title" sx={{ fontWeight: "500" }}>
          MPESA Payment Details
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body1" gutterBottom>
          <b>Paybill:</b> 709 777
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>Account No:</b> Your National ID Number
        </Typography>
        <Typography variant="body1" gutterBottom>
          KSh {amt}
        </Typography>
      </Box>
      <Card sx={{ boxShadow: "0px 4px 16px #60606040", p: "1em" }}>
        <Typography variant="title" sx={{ fontWeight: "500" }}>
          New Check
        </Typography>
        <Divider sx={{ my: 1 }} />
        <form autoComplete="off" onSubmit={createDocument}>
          <Stack spacing={2}>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel>Select Category *</InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={(e) => {
                      const category = e.target.value;
                      setSelectedCategory(category);
                      setAmt(category === "New Driver" ? "1,000" : "500");
                      setBody({ ...body, Category: e.target.value });
                    }}
                    label="Select Category *"
                  >
                    {[
                      "New Driver",
                      "Driver Renewal",
                      "New Rider",
                      "Rider Renewal",
                    ].map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  type="text"
                  label="MPESA Payment Code *"
                  variant="outlined"
                  fullWidth
                  value={body.MPESA}
                  onChange={(e) => setBody({ ...body, MPESA: e.target.value })}
                  sx={{ mb: 2 }}
                />
              </Grid2>
            </Grid2>

            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  type="number"
                  label="ID Number *"
                  variant="outlined"
                  fullWidth
                  value={body.IDNumber}
                  onChange={(e) =>
                    setBody({ ...body, IDNumber: e.target.value })
                  }
                  sx={{ mb: 2 }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  type="number"
                  label="Uber Phone Number *"
                  variant="outlined"
                  fullWidth
                  value={body.Phone}
                  onChange={(e) => setBody({ ...body, Phone: e.target.value })}
                  sx={{ mb: 2 }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <TextField
                  type="date"
                  label="Date of Birth *"
                  variant="outlined"
                  fullWidth
                  value={body.DOB}
                  onChange={(e) => setBody({ ...body, DOB: e.target.value })}
                  sx={{ mb: 2 }}
                />
              </Grid2>
            </Grid2>

            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel shrink>National ID *</InputLabel>
                  <TextField
                    type="file"
                    label="National ID *"
                    accept=".pdf,.png,.PNG,.jpg,.jpeg,.JPG,.JPEG"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setBody((prevBody) => ({
                          ...prevBody,
                          NationalID: e.target.files[0],
                        }));
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel shrink>Recent Passport *</InputLabel>
                  <TextField
                    type="file"
                    label="Recent Passport *"
                    accept=".pdf,.png,.PNG,.jpg,.jpeg,.JPG,.JPEG"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setBody((prevBody) => ({
                          ...prevBody,
                          Passport: e.target.files[0],
                        }));
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                  <InputLabel shrink>Police Clearance *</InputLabel>
                  <TextField
                    type="file"
                    label="Police Clearance *"
                    accept=".pdf,.png,.PNG,.jpg,.jpeg,.JPG,.JPEG"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setBody((prevBody) => ({
                          ...prevBody,
                          Police: e.target.files[0],
                        }));
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid2>
            </Grid2>

            {error && (
              <Alert color={error.includes("success") ? "success" : "warning"}>
                {error}
              </Alert>
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              sx={{ width: "100%", height: 50 }}
            >
              {loading ? <WaveLoading /> : "Submit"}
            </Button>
          </Stack>
        </form>
      </Card>
    </Stack>
  );
};
