import { React, useEffect } from "react";
import logo from "../assets/images/logo.png";

function NotFound(props) {
  useEffect(() => {
    logout();
  }, []);
  const logout = () => {
    fetch("/api/auth/logout", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        localStorage.setItem("webgfgfgggn", null);
        window.location.href = "/";
      })
      .catch((error) => {
        window.location.href = "/";
      });
  };
  return (
    <div className="notfound">
      <div>
        <img src={logo} alt="" />
        <p>Oops! this page does not exist</p>
        <button
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go Back
        </button>
        <a href="https://aropegroup.com">Visit our website</a>
      </div>
    </div>
  );
}

export default NotFound;
