import React, { useRef, useState } from "react";
import Input from "../Utils/Input";
import Button from "../Utils/Button";
import WaveLoading from "../Utils/WaveLoading";
import Select from "../Utils/Select";

export default function RegisterPopup(props) {
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({
    Email: null,
    Password: null,
    cPassword: null,
    Phone: null,
    Name: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const rfEmail = useRef();
  const rfPassword = useRef();
  const rfcPassword = useRef();
  const rfName = useRef();
  const rfPhone = useRef();

  const loginUser = () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Name = rfName.current.value;
    d.Phone = rfPhone.current.value;
    d.Password = rfPassword.current.value;
    d.cPassword = rfcPassword.current.value;
    updateBody(d);
    setIsError("");
    if (!body.Name) return setIsError("Name is required!");
    if (!body.Phone || body.Phone?.length != 10)
      return setIsError("Invalid phone number!");
    if (!validateEmail(body.Email))
      return setIsError("Please enter a valid email address!");
    if (!validatePassword(body.Password))
      return setIsError("Password must be at least 6 characters!");
    if (body.Password !== body.cPassword)
      return setIsError("Passwords do not match");
    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setIsLoading(true);
      fetch("/api/uberusers/register", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Registration failed!!");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            setIsError(data.success);
            localStorage.setItem("cilbup_ksa", data.token);
            setTimeout(() => {
              props.setIsLogin(true);
            }, 1000);
          } else {
            setIsError(data.error);
          }
        })
        .catch((err) => {
          setIsLoading(false);

          setIsError("Registration failed!");
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div className="login">
      <div className="container">
        <h1>Register</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="div2equal">
            <Input
              ref={rfName}
              label="Full Name *"
              type="text"
              placeholder="Enter your full name"
            />

            <Input
              ref={rfPhone}
              label="Phone Number *"
              type="number"
              placeholder="Enter a valid number"
            />
          </div>
          <Input
            ref={rfEmail}
            label="Email Address *"
            type="email"
            placeholder="Enter Email Address"
          />
          <div className="div2equal">
            <Input
              ref={rfPassword}
              label="Password *"
              type="password"
              placeholder="Enter Password"
            />
            <Input
              ref={rfcPassword}
              label="Confirm Password *"
              type="password"
              placeholder="Confirm Password"
            />
          </div>

          <h6>{isError}</h6>
          <Button value="Submit" handleClick={loginUser} />
        </form>
        <p>
          Already have an account?{" "}
          <span
            onClick={() => {
              props.setIsLogin(true);
            }}
          >
            Login here
          </span>
        </p>
        {isLoading && <WaveLoading />}
      </div>
    </div>
  );
}
