import { useState } from "react";
import logo2 from "../../assets/images/arope.jpg";
import { useLocation } from "react-router-dom";

export default function Navigation(props) {
  const location = useLocation();
  const pathname = location.pathname.split("/")[2];
  const Item = (params) => {
    return (
      <div
        style={{
          backgroundColor:
            pathname == params.url.replace("/", "")
              ? "#60606010"
              : "transparent",
        }}
      >
        <div
          onClick={() => {
            if (params.options.length === 0) {
              window.location.href = params.url;
            }
            if (params.url == "/logout") {
              localStorage.setItem("webgfgfgggn", null);
              window.location.href = "/";
            }
          }}
          className="item"
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: "auto 1fr auto",
          }}
        >
          <i aria-hidden="true" className={"fa " + params.icon}>
            {params.code}
          </i>
          <p>{params.txt}</p>
        </div>
        {params.options.length > 0 &&
          params.options.map((item, i) => {
            return (
              <a key={i} href={params.url + "/" + item}>
                {item}
              </a>
            );
          })}
      </div>
    );
  };

  return (
    <div className="navnavigation">
      <i
        onClick={() => {
          props.setActive(false);
        }}
        className="fa fa-times"
      ></i>
      <div className="logo">
        <img alt="" src={logo2} />
      </div>
      <hr />
      <Item
        url="/home"
        txt="Home"
        icon="fa-home"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="New Check"
        url="/home/checks"
        icon="fa-spinner"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Account"
        url="/home/account"
        icon="fa-user"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Logout"
        icon="fa-lock"
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
}
