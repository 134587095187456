import { useEffect, useState } from "react";
import "../../Styles/myhome.scss";
import Requests from "../Requests/Requests";

export default function MyHome(props) {
  const [currentUser, setUserID] = useState(null);

  const jwt = require("jsonwebtoken");
  useEffect(() => {
    const token = localStorage.getItem("webgfgfgggn");
    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/";
        } else {
          setUserID(decoded);
        }
      } catch (error) {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
  }, []);
  return (
    <div className="myhome">
      <div className="mhead">
        <div className="ctn">
          <h1>Welcome,</h1>
          <h2>{currentUser?.Name}</h2>
        </div>
        <div className="floating">
          <div className="border">
            <div></div>
            <div></div>
          </div>
          <div className="fitem">
            <h4>Minimum Requirements</h4>
            <div></div>
          </div>
          <div className="border">
            <div></div>
            <div></div>
          </div>
          <div className="fitem">
            <h4>1</h4>
            <div>
              <h5>Drivers</h5>
              <p>{"->"} Minimum age – 24 years</p>
              <p>{"->"} Driving License Classes – D1, D2 or D3</p>
              <p>
                {"->"} Good Conduct – Valid Within one year and NIL Offense
                Record
              </p>
            </div>
          </div>
          <div className="border">
            <div></div>
            <div></div>
          </div>
          <div className="fitem">
            <h4>2</h4>
            <div>
              <h5>Riders/Boda</h5>
              <p>{"->"} Minimum age – 22 years</p>
              <p>{"->"} Driving License Classes – A2</p>
              <p>
                {"->"} Good Conduct - Valid Within one year and NIL Offense
                Record
              </p>
            </div>
          </div>
          <div className="border">
            {" "}
            <div></div>
            <div></div>
          </div>
        </div>
      </div>

      <Requests />
      <br />
      <br />
    </div>
  );
}
