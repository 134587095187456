import React from "react";
import { useState } from "react";
import Header from "../components/Utils/Header";
import "../Styles/landing.scss";
import LoginPopup from "../components/Landing/LoginPopup";
import ForgotPassword from "../components/Landing/ForgotPassword";
import RegisterPopup from "../components/Landing/RegisterPopup";
import Footer from "../components/Utils/footer";

export default function TermsAndConditions(props) {
  const [isLogin, setIsLogin] = useState(null);
  const [isForgot, setIsForgot] = useState(null);
  const [isRegister, setIsRegister] = useState(null);
  return (
    <div className="terms">
      <Header setIsLogin={setIsLogin} setIsRegister={setIsRegister} />
      <div className="pcontent">
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to Arope Group Ltd ("Arope," "we," "us," or "our"). By
          accessing or using our website (the "Site") and our services
          (collectively, the "Services"), you agree to be bound by these Terms
          and Conditions ("Terms"). Please read these Terms carefully before
          using our Site or Services.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using the Site or Services, you acknowledge that you
          have read, understood, and agree to be bound by these Terms, including
          any additional terms and conditions and policies referenced herein. If
          you do not agree with these Terms, you must not use our Site or
          Services.
        </p>

        <h2>2. Background Screening Services</h2>
        <p>
          Arope Group Ltd provides background screening services to individuals
          and organizations seeking to gather information about individuals for
          lawful purposes. Our Services may include, but are not limited to,
          criminal record checks, employment verifications, educational
          verifications, reference checks, and other similar services. Please
          note that Arope Group Ltd does not make any hiring decisions on behalf
          of its clients and does not guarantee the accuracy or completeness of
          the information provided.
        </p>

        <h2>3. User Obligations</h2>
        <p>By using our Site or Services, you represent and warrant that:</p>
        <ul>
          <li>
            You are at least 18 years old and have the legal capacity to enter
            into a binding agreement.
          </li>
          <li>
            You will provide accurate and complete information when using our
            Services and will not impersonate or misrepresent your identity.
          </li>
          <li>
            You will comply with all applicable laws and regulations when using
            our Site or Services.
          </li>
          <li>
            You will not use our Services for any illegal, unauthorized, or
            prohibited purposes.
          </li>
          <li>
            You will not attempt to interfere with or disrupt the proper
            functioning of our Site or Services.
          </li>
        </ul>
      </div>
      <Footer />
      {isLogin && (
        <LoginPopup
          setIsForgot={setIsForgot}
          setIsLogin={setIsLogin}
          setIsRegister={setIsRegister}
        />
      )}
      {isForgot && (
        <ForgotPassword
          setIsForgot={setIsForgot}
          setIsLogin={setIsLogin}
          setIsRegister={setIsRegister}
        />
      )}
      {isRegister && (
        <RegisterPopup
          setIsForgot={setIsForgot}
          setIsLogin={setIsLogin}
          setIsRegister={setIsRegister}
        />
      )}
    </div>
  );
}
