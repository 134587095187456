import React from "react";
import logo2 from "../../assets/images/logo.png";
import { useLocation } from "react-router-dom";
import userguide from "../../assets/Arope Group ERP User Guide.pdf";

function NavLink(props) {
  const location = useLocation();
  let link;
  if (props.url === location.pathname) {
    link = (
      <h4
        className="underline"
        onClick={() => {
          window.location.href = props.url;
        }}
      >
        {props.txt}
      </h4>
    );
  } else {
    link = (
      <h4
        onClick={() => {
          window.location.href = props.url;
        }}
      >
        {props.txt}
      </h4>
    );
  }
  return link;
}

export default function ModalHeader(props) {
  return (
    <div className="headermodal">
      <div className="container">
        <div className="itemsLogo">
          <div
            onClick={() => {
              window.location.href = "/";
            }}
            className="logo"
          >
            <img src={logo2} alt="Arope Group" />
          </div>
        </div>
        <br />
        <br />
        <NavLink txt="Home" url="/" active={props.active} />
        <NavLink
          txt="Go to Our Website"
          url="https://aropegroup.com"
          active={props.active}
        />
        <NavLink
          txt="Terms and Conditions"
          url="/terms"
          active={props.active}
        />
        <NavLink txt="Privacy Policy" url="/policy" active={props.active} />
        <a href={userguide} target="_blank">
          User Guide
        </a>
        <br />
        <button
          onClick={() => {
            props.setClicked(false);
            props.setIsLogin(true);
            props.setIsRegister(false);
          }}
        >
          Login
        </button>
        <button
          onClick={() => {
            props.setClicked(false);
            props.setIsLogin(false);
            props.setIsRegister(true);
          }}
        >
          Register
        </button>
        <i
          onClick={() => {
            props.setClicked(false);
          }}
          className="fa fa-close"
        >
          &#xf00d;
        </i>
      </div>
    </div>
  );
}
